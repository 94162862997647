* {
	box-sizing: border-box;
}
.rotatingList {
	width: 100%;
	height: 180px;
	position: relative;
}
.main-sponsor {
	width: 100%;
}
.padding-title {
	padding-bottom: 70px !important;
}
.list,
.list-bis {
	width: 3700px;
	position: absolute;
	white-space: nowrap;
	display: inline-block;
	animation-name: pan;
	animation-duration: 10s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
.list-bis {
	left: 1850px;
}
.card {
	display: inline-block;
	margin-top: 0px;
	margin-left: 30px;
	float: left;
	overflow: hidden;
	border: 5px solid #fff;
	text-align: center;
	/*text-align: center;
	border: 5px solid rgb(66, 64, 64);
	height: 150px;
	width: 350px;
	text-align: center;
	padding-top: 50px;*/
}

.rotatingList {
	overflow-x: hidden;
	overflow-y: hidden;
}

@keyframes pan {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-1850px);
	}
}
