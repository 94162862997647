.banner_part {
	position: relative;
	height: 682px;
	margin-top: 10%;
	display: flex;
	align-items: center;
}

@media (max-width: 991px) {
	.banner_part {
		height: 550px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner_part {
		height: 550px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part {
		height: 420px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
	.banner_part {
		height: 600px;
	}
}

.banner_part:after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	background-color: #233e62;
	width: 100px;
	height: 618px;
}

@media (max-width: 991px) {
	.banner_part:after {
		display: none;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part:after {
		display: none;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
	.banner_part:after {
		display: none;
	}
}

@media (max-width: 991px) {
	.banner_part .banner_text {
		margin-top: -100px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part .banner_text {
		margin-top: 75%;
	}
	.marginnn {
		padding-top: 20%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner_part .banner_text {
		margin-top: 75%;
	}
}
/* @media only screen and (min-width: 568px) and (max-width: 768px) {
	.banner_part .banner_text {
		margin-top: 75%;
	}
} */

.banner_part .banner_text h1 {
	color: #233e62;
	font-size: 68px;
	font-weight: 500;
	line-height: 78px;
	margin-bottom: 10px;
}
.banner_text_iner2 {
	font-size: 18px !important;
}
@media (max-width: 576px) {
	.banner_part .banner_text h1 {
		font-size: 30px;
		margin-bottom: 15px;

		line-height: 1.3;
	}
	.banner_text_iner2 {
		font-size: 12px !important;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.banner_part .banner_text h1 {
		font-size: 30px;
		margin-bottom: 15px;
		line-height: 1.3;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner_part .banner_text h1 {
		font-size: 30px;
		margin-bottom: 15px;
		line-height: 1.3;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part .banner_text h1 {
		font-size: 40px;
		margin-bottom: 15px;
		line-height: 1.4;
	}
}

.banner_part .banner_text h1 span {
	color: #ee8425;
}

.banner_part .banner_text p {
	font-size: 20px;
	line-height: 1.5;
}

.banner_part .banner_img {
	position: absolute;
	right: 0;
	top: 0;
	max-width: 55%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
	.banner_part .banner_img {
		max-width: 55%;
		top: auto;
		bottom: 0;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part .banner_img {
		max-width: 55%;
		top: auto;
		bottom: 0;
	}
}

@media (max-width: 576px) {
	.banner_part .banner_img {
		max-width: 75%;
		top: auto;
		bottom: 0;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.banner_part .banner_img {
		max-width: 55%;
		top: auto;
		bottom: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner_part .banner_img {
		max-width: 55%;
		top: auto;
		bottom: 0;
	}
}

.banner_part .banner_img .pattern_img {
	position: absolute;
	left: -133px;
	bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part .banner_img .pattern_img {
		max-width: 85%;
	}
}

@media (max-width: 576px) {
	.banner_part .banner_img .pattern_img {
		max-width: 60%;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.banner_part .banner_img .pattern_img {
		max-width: 55%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner_part .banner_img .pattern_img {
		max-width: 55%;
	}
}

.banner_part .btn_1 {
	margin-top: 50px;
}

@media (max-width: 991px) {
	.banner_part .btn_1 {
		margin-top: 20px;
		padding: 12px 25px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.banner_part .btn_1 {
		margin-top: 20px;
	}
}

.section-tittle h2 {
	color: #f9488b;
	font-size: 50px;
	display: block;
	font-weight: 600;
	line-height: 1.1;
	margin-bottom: 30px;
}

.about-caption p {
	font-size: 16px;
	color: #64676c;
	line-height: 1.6;
	margin-bottom: 63px;
	padding-right: 50px;
}

.section-tittle p {
	color: #391f14;
	font-size: 20px;
	font-weight: 300;
}

.section-tittle p {
	color: #391f14;
	font-size: 20px;
	font-weight: 300;
}

.about-font-img {
	z-index: 1;
	position: absolute;
	bottom: -115px;
	height: 200px;
	width: 200px;
	right: 0;
}
.about-back-img {
	z-index: 0;
	height: 150px;
	width: 150px;
}

.about-img {
	position: relative;
}
.card-title {
	padding-top: 5%;
	text-align: left;
	padding-left: 5%;
}
.contact-body {
	text-align: left;
	padding-top: 2%;
	padding-left: 10%;
	left: 0% !important;
}

.card {
	height: 450px;
}

@media (max-width: 991px) {
	.card {
		height: 350px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.card {
		height: 350px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.card {
		height: 450px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
	.card {
		height: 450px;
	}
}

.hotel-card {
	border: none;
	box-shadow: 1px 1px 5px #7affbe;
	border-radius: 0;
	margin-bottom: 5px;
	width: 90%;
}

.map-responsive {
}
.map-responsive iframe {
	width: 100%;
}
