@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body,
html {
	height: 100%;
	font-family: Montserrat-Regular, sans-serif;
}

/*---------------------------------------------*/

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-family: 'Oswald', sans-serif;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}

/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
	outline: none;
	border: none;
}

textarea:focus,
input:focus {
	border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}
input:focus:-moz-placeholder {
	color: transparent;
}
input:focus::-moz-placeholder {
	color: transparent;
}
input:focus:-ms-input-placeholder {
	color: transparent;
}

textarea:focus::-webkit-input-placeholder {
	color: transparent;
}
textarea:focus:-moz-placeholder {
	color: transparent;
}
textarea:focus::-moz-placeholder {
	color: transparent;
}
textarea:focus:-ms-input-placeholder {
	color: transparent;
}

input::-webkit-input-placeholder {
	color: #aaaaaa;
}
input:-moz-placeholder {
	color: #aaaaaa;
}
input::-moz-placeholder {
	color: #aaaaaa;
}
input:-ms-input-placeholder {
	color: #aaaaaa;
}

textarea::-webkit-input-placeholder {
	color: #aaaaaa;
}
textarea:-moz-placeholder {
	color: #aaaaaa;
}
textarea::-moz-placeholder {
	color: #aaaaaa;
}
textarea:-ms-input-placeholder {
	color: #aaaaaa;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.bg-contact100 {
	width: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.container-contact100 {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background-image: linear-gradient(120deg, #ee8425 0%, #f9488b 100%),
		linear-gradient(120deg, #ee8425 0%, #f9488b 100%);
}

.wrap-contact100 {
	width: 1163px;
	background: #fff;
	border-radius: 10px;
	overflow: hidden;

	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 110px 130px 157px 148px;
}

/*------------------------------------------------------------------
[  ]*/
.contact100-pic {
	position: relative;
	width: 310px;
	padding-top: 55px;
}

.contact100-pic img {
	max-width: 100%;
}

/*------------------------------------------------------------------
[  ]*/
.contact100-form {
	width: 390px;
}

.contact100-form-title {
	display: block;
	font-family: 'Oswald', sans-serif;
	font-size: 24px;
	color: #333333;
	line-height: 1.2;
	text-align: left;
	padding-bottom: 36px;
}

input.input100 {
	height: 50px;
	border-radius: 25px;
	padding: 0 30px 0 50px;
}

input.input100[name='email'] {
	padding: 14px 30px;
}

textarea.input100 {
	min-height: 150px;
	border-radius: 25px;
	padding: 14px 30px;
}

/*---------------------------------------------*/
.wrap-input100 {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: 10px;
}

.input100 {
	display: block;
	width: 100%;
	background: #e6e6e6;
	font-family: 'Oswald', sans-serif;
	font-size: 15px;
	line-height: 1.5;
	color: #666666;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
	display: block;
	position: absolute;
	border-radius: 25px;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 0px 0px;
	color: rgba(132, 106, 221, 0.5);
}

@-webkit-keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 60px 25px;
		opacity: 0;
	}
}

@keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 60px 25px;
		opacity: 0;
	}
}

.symbol-input100 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: absolute;
	border-radius: 25px;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 30px;
	pointer-events: none;
	color: #aaaaaa;
	font-size: 15px;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 120px;
}

.contact100-form-btn {
	width: 100%;
	height: 50px;
	border: solid;
	border-radius: 25px;
	border-color: #f50136;
	background: #fff;
	font-family: 'Oswald', sans-serif;
	font-size: 15px;
	line-height: 1.5;
	color: rgb(0, 0, 0);
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.contact100-form-btn:hover {
	background: #f50136;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1200px) {
	.contact100-pic {
		width: 33.5%;
	}

	.contact100-form {
		width: 44%;
	}
}

@media (max-width: 992px) {
	.wrap-contact100 {
		padding: 110px 80px 157px 90px;
	}

	.contact100-pic {
		width: 35%;
	}

	.contact100-form {
		width: 55%;
	}
}

@media (max-width: 768px) {
	.wrap-contact100 {
		padding: 110px 80px 157px 80px;
	}

	.contact100-pic {
		display: none;
	}

	.contact100-form {
		width: 100%;
	}
}

@media (max-width: 576px) {
	.wrap-contact100 {
		padding: 110px 15px 157px 15px;
	}
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
	position: relative;
}

.alert-validate::before {
	content: attr(data-validate);
	position: absolute;
	max-width: 70%;
	background-color: white;
	border: 1px solid #c80000;
	border-radius: 13px;
	padding: 4px 25px 4px 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 8px;
	pointer-events: none;

	font-family: Montserrat-Medium;
	color: #c80000;
	font-size: 13px;
	line-height: 1.4;
	text-align: left;

	visibility: hidden;
	opacity: 0;

	-webkit-transition: opacity 0.4s;
	-o-transition: opacity 0.4s;
	-moz-transition: opacity 0.4s;
	transition: opacity 0.4s;
}

.alert-validate::after {
	content: '\f06a';
	font-family: FontAwesome;
	display: block;
	position: absolute;
	color: #c80000;
	font-size: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 13px;
}

.alert-validate:hover:before {
	visibility: visible;
	opacity: 1;
}

@media (max-width: 992px) {
	.alert-validate::before {
		visibility: visible;
		opacity: 1;
	}
}
