@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
body {
	color: #797979;

	font-family: 'Nunito Sans', Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.8;
}
p {
	font-family: 'Nunito Sans', Arial, sans-serif !important;
	color: gray;
}
@media (max-width: 575px) {
	body {
		text-align: center !important;
	}
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
a:hover {
	text-decoration: none;
}
button {
	cursor: pointer;
}
button:focus {
	outline: 0;
	box-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Oswald', sans-serif;
	color: #233e62;
	line-height: 1.5;
}
h1,
.h1 {
	font-size: 60px;
	font-weight: 700;
}
h2,
.h2 {
	font-size: 24px;
	font-weight: 600;
}
h3,
.h3 {
	font-size: 20px;
	font-weight: 500;
}
h4,
.h4 {
	font-size: 18px;
}
[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
	margin: 0;
}
.primary-text2 {
	color: #36a871;
}
.primary-text4 {
	color: rgb(39, 90, 207);
}
.primary-text3 {
	color: #0f867e;
}
.primary-text {
	color: #233e62 !important;
	font-weight: 600;
	/* padding: 10px; */
}
.primary-bg {
	/* background-color: #f50136; */
	background-image: linear-gradient(120deg, #0d1130 0%, #7affbe 100%),
		linear-gradient(120deg, #ee8425 0%, #f9488b 100%);
}
.bg-gray {
	background: #f7f7f7;
}
.section-padding--small {
	padding: 70px 0;
}
@media (min-width: 992px) {
	.section-padding--small {
		padding: 50px 0;
	}
}
.themes {
	padding-bottom: 2%;
	padding-top: 1%;
}
.section-margin {
	margin: 0px 0;
	padding-top: 80px;
}
@media (min-width: 992px) {
	.section-margin {
		margin: 0px 0;
	}
}
.section-margin--large {
	margin: 0px 0;
}
@media (min-width: 992px) {
	.section-margin--large {
		margin: 0px 0;
	}
}
.section-padding {
	padding: 70px 0;
}
@media (min-width: 992px) {
	.section-padding {
		padding: 120px 0;
	}
}
.section-padding--large {
	padding: 70px 0;
}
@media (min-width: 992px) {
	.section-padding--large {
		padding: 130px 0;
	}
}
.pb-98px {
	padding-bottom: 58px;
}
@media (min-width: 992px) {
	.pb-98px {
		padding-bottom: 98px;
	}
}
.pb-80px {
	padding-bottom: 58px;
}
@media (min-width: 992px) {
	.pb-80px {
		padding-bottom: 80px;
	}
}
.mb-50px {
	margin-bottom: 50px;
}
.tab-pane.active {
	animation: slide-down 0.5s ease-out;
	animation-iteration-count: 1;
}
@keyframes slide-down {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes reactive {
	to {
		width: auto;
	}
}
.tab-pane.active:checked {
	animation-name: reactive;
}
.tab-pane.non-active {
	animation: none;
}
.header_area {
	position: fixed;
	width: 100%;
	z-index: 99;
	transition: background 0.4s, all 0.3s linear;
	transition: all 0.6s ease !important;
}
.box-shadow {
	box-shadow: -0 2px 3px rgba(0, 0, 0, 0.2);
}
nav {
	background-color: #fff !important;
	transition: all 0.6s ease !important;
}
.header_area .navbar-right {
	min-width: 200px;
	text-align: right;
}

.color-transp {
	color: #fff;
}

@media (max-width: 991px) {
	.header_area .navbar-right {
		margin: 20px 0;
	}
}
.header_area .navbar-brand {
	margin-right: 30px;
	padding: 0;
}
.header_area .navbar-social {
	min-width: 95px;
	float: right;
}
.header_area .navbar-social li {
	margin-right: 12px;
	color: #0a44c9;
}
.header_area .navbar-social li i,
.header_area .navbar-social li span {
	font-size: 13px;
	color: #0a44c9;
}
.header_area .navbar-social li:last-child {
	margin-right: 0;
}
.header_area .navbar-social li a:hover i,
.header_area .navbar-social li span {
	color: #275acf;
}
.header_area .menu_nav {
	width: 100%;
}
.header_area .navbar {
	background: #275acf;
	border: 0px;
	border-radius: 0px;
	width: 100%;
	padding-top: 0;
	padding-bottom: 0;
	transition: all 0.6s ease !important;
}
.header_area .navbar .nav-right {
	min-width: 130px;
}
.header_area .navbar .nav-right a {
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-size: 15px;
	font-weight: 500;
	color: #0f867e;
}
.header_area .navbar .nav .nav-item {
	margin-right: 38px;
}
@media (max-width: 500px) {
	.header_area .navbar .nav .nav-item {
		padding-left: 5%;
	}
}
.colornav {
	color: #04287c;
}
.header_area .navbar .nav .nav-item .nav-link {
	font-family: 'Roboto', sans-serif;
	cursor: pointer;
	text-transform: capitalize;
	font-weight: 500;
	padding: 0 5px 0 2px;
	display: inline-block;
	transition: all 0.6s ease !important;
}
.header_area .navbar .nav .nav-item .nav-link:after {
	display: none;
}
.header_area .navbar .nav .nav-item:hover .nav-link,
.header_area .navbar .nav .nav-item.active .nav-link {
	color: #275acf;
	opacity: 1;
}
.header_area .navbar .nav .nav-item.submenu {
	position: relative;
}
.header_area .navbar .nav .nav-item.submenu ul {
	border: none;
	padding: 0px;
	border-radius: 0px;
	box-shadow: none;
	margin: 0px;
	background: #fff;
}
.nav-item:active {
	color: #ea0763;
	opacity: 1;
}
.nav-active {
	color: #ea0763;
	opacity: 1;
}
@media (min-width: 992px) {
	.header_area .navbar .nav .nav-item.submenu ul {
		position: absolute;
		top: 120%;
		left: 0px;
		min-width: 200px;
		text-align: left;
		opacity: 0;
		transition: all 300ms ease-in;
		visibility: hidden;
		display: block;
		border: none;
		padding: 0px;
		border-radius: 0px;
		box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4);
	}
}
.header_area .navbar .nav .nav-item.submenu ul:before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: #eeeeee transparent transparent transparent;
	position: absolute;
	right: 24px;
	top: 45px;
	z-index: 3;
	opacity: 0;
	transition: all 400ms linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item {
	display: block;
	float: none;
	margin-right: 0px;
	border-bottom: 1px solid #ededed;
	margin-left: 0px;
	transition: all 0.4s linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
	line-height: 45px;
	color: #3b1d82 !important;
	padding: 0px 30px;
	transition: all 150ms linear;
	display: block;
	margin-right: 0px;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
	border-bottom: none;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
	background-color: #3b1d82;
	color: #fff !important;
}
@media (min-width: 992px) {
	.header_area .navbar .nav .nav-item.submenu:hover ul {
		visibility: visible;
		opacity: 1;
		top: 100%;
	}
}
.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
	margin-top: 0px;
}
.header_area .navbar .nav .nav-item:last-child {
	margin-right: 0px;
}
.header_area .navbar .search {
	font-size: 12px;
	line-height: 60px;
	display: inline-block;
	margin-left: 80px;
}
.header_area .navbar .search i {
	font-weight: 600;
}
.header_area.navbar_fixed .main_menu {
	position: fixed;
	width: 100%;
	top: -70px;
	left: 0;
	right: 0;
	/* background: #fff; */
	background: #0d1130;
	z-index: 99;
	transform: translateY(70px);
	transition: transform 500ms ease, background 500ms ease;
	-webkit-transition: transform 500ms ease, background 500ms ease;
	box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
	line-height: 20px;
}
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item:hover .nav-link,
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item.active .nav-link {
	color: #3b1d82;
}
@media (min-width: 992px) {
	.header_area.white_menu .navbar .navbar-brand img {
		display: none;
	}
	.header_area.white_menu .navbar .navbar-brand img + img {
		display: inline-block;
	}
}
@media (max-width: 991px) {
	.header_area.white_menu .navbar .navbar-brand img {
		display: inline-block;
	}
	.header_area.white_menu .navbar .navbar-brand img + img {
		display: none;
	}
}
.header_area.white_menu .navbar .nav .nav-item .nav-link {
	color: #fff;
}
.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
	display: inline-block;
}
.header_area.white_menu.navbar_fixed
	.main_menu
	.navbar
	.navbar-brand
	img
	+ img {
	display: none;
}
.header_area.white_menu.navbar_fixed
	.main_menu
	.navbar
	.nav
	.nav-item
	.nav-link {
	line-height: 70px;
}
.top_menu {
	background: #ec9d5f;
}
.top_menu .float-left a {
	line-height: 40px;
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	font-size: 12px;
	margin-right: 50px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.top_menu .float-left a:last-child {
	margin-right: 0px;
}
.top_menu .float-right .pur_btn {
	color: #fff;
	line-height: 40px;
	display: block;
	padding: 0px 40px;
	font-weight: 500;
	font-size: 12px;
}
@media (max-width: 1619px) {
	.header_area .navbar .search {
		margin-left: 40px;
	}
}
@media (max-width: 1199px) {
	.header_area .navbar .nav .nav-item {
		margin-right: 28px;
	}
	.home_banner_area .banner_inner {
		padding: 100px 0px;
	}

	.header_area .navbar .tickets_btn {
		margin-left: 40px;
	}
	.home_banner_area .banner_inner .banner_content h2 br {
		display: none;
		font-size: 36px;
	}
	.home_banner_area .banner_inner .banner_content h2 {
		font-size: 36px;
		line-height: 45px;
	}
}
.around {
}
@media (max-width: 991px) {
	.navbar-toggler {
		border: none;
		border-radius: 0px;
		padding: 0px;
		cursor: pointer;
		margin-top: 27px;
		margin-bottom: 23px;
	}
	.header_area .navbar {
		background: #fff;
	}
	.header_area .navbar .nav-item.active .nav-link {
		color: #3b1d82 !important;
	}
	.navbar-toggler[aria-expanded='false'] span:nth-child(2) {
		opacity: 1;
	}
	.navbar-toggler[aria-expanded='true'] span:nth-child(2) {
		opacity: 0;
	}
	.navbar-toggler[aria-expanded='true'] span:first-child {
		transform: rotate(-45deg);
		position: relative;
		top: 7.5px;
	}
	.navbar-toggler[aria-expanded='true'] span:last-child {
		transform: rotate(45deg);
		bottom: 6px;
		position: relative;
	}
	.navbar-toggler span {
		display: block;
		width: 25px;
		height: 3px;
		background: #0a44c9;
		margin: auto;
		margin-bottom: 4px;
		transition: all 400ms linear;
		cursor: pointer;
	}
	.navbar .container {
		padding-left: 15px;
		padding-right: 15px;
	}
	.nav {
		padding: 0px 0px;
	}
	.header_top .nav {
		padding: 0px;
	}
	.header_area .navbar .nav .nav-item .nav-link {
		padding: 12px 0;
		margin-right: 0px;
		display: block;
		border-bottom: 1px solid #ededed33;
		border-radius: 0px;
	}
	.header_area .navbar .search {
		margin-left: 0px;
	}
	.header_area .navbar-collapse {
		max-height: 340px;
		overflow-y: scroll;
	}
	.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
		padding: 0px 15px;
	}
	.header_area .navbar .nav .nav-item {
		margin-right: 0px;
	}
	.home_banner_area .banner_inner .banner_content .banner_map_img {
		display: none;
	}
	.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
		line-height: 40px;
	}
	.header_area.white_menu.navbar_fixed
		.main_menu
		.navbar
		.nav
		.nav-item
		.nav-link {
		line-height: 40px;
	}
	.header_area.white_menu .navbar .nav .nav-item .nav-link {
		color: #222;
	}
	.categories_post img {
		width: 100%;
	}
	.categories_post {
		max-width: 360px;
		margin: 0 auto;
	}
	.blog_categorie_area .col-lg-4 {
		margin-top: 30px;
	}
	.blog_area {
		padding-bottom: 80px;
	}
	.single-post-area .blog_right_sidebar {
		margin-top: 30px;
	}
	.contact_info {
		margin-bottom: 50px;
	}
	.home_banner_area .donation_inner {
		margin-bottom: -30px;
	}
	.home_banner_area .dontation_item {
		max-width: 350px;
		margin: auto;
	}
	.footer_area .col-sm-6 {
		margin-bottom: 30px;
	}
	.footer-area div {
		overflow-x: auto;
		overflow-y: auto;
	}
	.footer_area .footer_inner {
		margin-bottom: -30px;
	}
	.news_widget {
		padding-left: 0px;
	}
	.home_banner_area .banner_inner .home_left_img {
		display: none;
	}
	.header_area .navbar .tickets_btn {
		display: none;
	}
	.home_banner_area .banner_inner {
		padding: 70px 0px 100px 0px;
	}
	.left_side_text {
		margin-bottom: 50px;
	}
	.price_item {
		max-width: 360px;
		margin: 0px auto 30px;
	}
	.price_inner {
		margin-bottom: -30px;
	}
}
@media (max-width: 767px) {
	.home_banner_area {
		min-height: 500px;
	}
	.home_banner_area .banner_inner {
		min-height: 500px;
	}
	.home_banner_area .banner_inner .banner_content {
		margin-top: 0px;
	}
	.blog_banner .banner_inner .blog_b_text h2 {
		font-size: 40px;
		line-height: 50px;
	}
	.blog_info.text-right {
		text-align: left !important;
		margin-bottom: 10px;
	}
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 30px;
	}
	.home_banner_area .banner_inner .banner_content p br {
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h3 span {
		line-height: 45px;
		padding-bottom: 0px;
		padding-top: 0px;
	}
	.footer-bottom {
		text-align: center;
	}
	.footer-bottom .footer-social {
		text-align: center;
		margin-top: 15px;
	}
	.made_life_inner .nav.nav-tabs li {
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 15px;
	}
	.made_life_inner .nav.nav-tabs {
		margin-bottom: -15px;
	}
	.made_life_area.made_white .left_side_text {
		margin-bottom: 0px;
		margin-top: 30px;
	}
}
@media (max-width: 575px) {
	.top_menu {
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h2 {
		font-size: 28px;
		line-height: 38px;
	}
	.home_banner_area {
		min-height: 450px;
	}
	.home_banner_area .banner_inner {
		min-height: 450px;
	}
	.blog_banner .banner_inner .blog_b_text {
		margin-top: 0px;
	}
	.home_banner_area .banner_inner .banner_content img {
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h5 {
		margin-top: 0px;
	}
	.p_120 {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.main_title h2 {
		font-size: 25px;
	}
	.sample-text-area {
		padding: 70px 0 70px 0;
	}
	.generic-blockquote {
		padding: 30px 15px 30px 30px;
	}
	.blog_details h2 {
		font-size: 20px;
		line-height: 30px;
	}
	.blog_banner .banner_inner .blog_b_text h2 {
		font-size: 28px;
		line-height: 38px;
	}
	.footer-area {
		padding: 70px 0px;
	}
	.pad_top {
		padding-top: 70px;
	}
	.pad_btm {
		padding-bottom: 70px;
	}
}
@media (max-width: 480px) {
	.header_area .navbar-collapse {
		max-height: 250px;
	}
	.home_banner_area .banner_inner .banner_content {
		padding: 30px 15px;
		margin-top: 0px;
	}
	.banner_content .white_btn {
		display: block;
	}
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 24px;
	}
	.banner_area .banner_inner .banner_content h2 {
		font-size: 32px;
	}
	.comments-area .thumb {
		margin-right: 10px;
	}
	.testi_item .media .d-flex {
		padding-right: 0px;
	}
	.testi_item .media .d-flex img {
		width: auto;
		margin-bottom: 15px;
	}
	.testi_item .media {
		display: block;
	}
	.banner_btn {
		margin-bottom: 20px;
	}
}
.hero-banner-img {
	width: 100%;
}
@media (max-width: 1000px) {
	.hero-banner-img {
		padding-top: 80px;
		width: 100%;
	}
}
.hero-banner {
	position: relative;

	height: 30% !important;
	padding-top: 60% !important;
	background: url('./assets/images/bg1.png') no-repeat !important;
	background-position-x: left !important;

	/* background: url('./assets/images/conference.png') left center no-repeat !important; */
	background-size: cover !important;
}
/* .hero-banner .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	opacity: 0.2;
	background: #36a871;
	background: -moz-linear-gradient(left, #36a871 0%, #1b9de3 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, #36a871),
		color-stop(100%, #1b9de3)
	);
	background: -webkit-linear-gradient(left, #36a871 0%, #1b9de3 100%);
	background: -o-linear-gradient(left, #36a871 0%, #1b9de3 100%);
	background: -ms-linear-gradient(left, #36a871 0%, #1b9de3 100%);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#36a871),
		to(#1b9de3)
	);
	background: linear-gradient(to right, #36a871 0%, #1b9de3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1566ad', endColorstr='#1b9de3', GradientType=1 );
} */
/* .hero-banner::before {
	position: absolute;
	padding: 70px 0;
	background: linear-gradient(120deg, #ee8425 0%, hsl(337, 94%, 63%) 100%),
		linear-gradient(120deg, #ee8425 0%, #f9488b 100%);
	background-size: cover;
} */
/* @media (min-width: 768px) {
	.hero-banner {
		padding: 300px 0;
	}
}
@media (min-width: 1200px) {
	.hero-banner {
		padding: 400px 0;
	}
}
@media (min-width: 200) {
	.hero-banner {
		padding: 200px 0;
	}
} */
.hero-banner div a {
	text-decoration: none;
}
.hero-banner-sm {
	padding-top: 80px;
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.hero-banner-sm {
		padding-top: 170px;
		padding-bottom: 170px;
	}
}
.hero-banner h1 {
	color: #fff;
	margin-bottom: 25px;
	font-size: 28px;
}
@media (min-width: 768px) {
	.hero-banner h1 {
		font-size: 60px;
		margin-bottom: 45px;
	}
}
.hero-banner h2 {
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 0;
}
@media (min-width: 768px) {
	.hero-banner h2 {
		font-size: 42px;
	}
}
.hero-banner p {
	font-size: 22px;
	color: #fff;
	margin-bottom: 0;
}
.hero-banner-icon {
	color: #fff;
	display: inline-block;
}
@media (min-width: 768px) {
	.hero-banner-icon {
		margin-bottom: 10px;
	}
}
.hero-banner-icon i,
.hero-banner-icon span {
	font-size: 35px;
}
@media (min-width: 768px) {
	.hero-banner-icon i,
	.hero-banner-icon span {
		font-size: 60px;
	}
}
.hero-banner-icon [class^='flaticon-']:before,
.hero-banner-icon [class*=' flaticon-']:before,
.hero-banner-icon [class^='flaticon-']:after,
.hero-banner-icon [class*=' flaticon-']:after {
	font-size: 35px;
}
@media (min-width: 768px) {
	.hero-banner-icon [class^='flaticon-']:before,
	.hero-banner-icon [class*=' flaticon-']:before,
	.hero-banner-icon [class^='flaticon-']:after,
	.hero-banner-icon [class*=' flaticon-']:after {
		font-size: 60px;
	}
}
.banner-breadcrumb {
	display: inline-block;
}
.banner-breadcrumb .breadcrumb {
	background: transparent;
	padding: 0;
}
.banner-breadcrumb .breadcrumb-item {
	padding: 0.1rem;
}
.banner-breadcrumb .breadcrumb-item a {
	text-transform: capitalize;
	color: #fff;
}
.banner-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
	color: #fff;
	padding-left: 0.2rem;
	padding-right: 0.4rem;
}
.banner-breadcrumb .breadcrumb-item.active {
	color: #fff;
}
.section-intro {
	max-width: 640px;
	margin-right: auto;
	margin-left: auto;
}
.section-intro__title {
	text-transform: uppercase;
	margin-bottom: 0;
}
.section-intro h2 {
	font-size: 26px;
}
@media (min-width: 600px) {
	.section-intro h2 {
		font-size: 36px;
	}
}
.section-intro-white > * {
	color: #fff;
}
.innovative-wrapper h3 {
	margin-bottom: 25px;
}
.innovative-wrapper p {
	margin-bottom: 0;
}
.clockdiv {
	margin-bottom: 35px;
}
.clockdiv li {
	display: inline-block;
	margin-right: 30px;
}
@media (max-width: 320px) {
	.clockdiv li {
		margin-right: 0;
	}
}
.clockdiv li:last-child {
	margin-right: 0;
}
.clockdiv h1 {
	font-size: 27px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 1;
	color: #3b1d82;
}
.clockdiv .smalltext {
	font-size: 14px;
	font-weight: 300;
}
.clockdiv .clockdiv-single {
	width: 130px;
	height: 145px;
	text-align: center;
	padding-top: 45px;
}
.clockdiv .clockdiv-day {
	background: url(/src/assets/images/bg/countdown-day.png) left center no-repeat;
	background-size: cover;
}
.clockdiv .clockdiv-hour {
	background: url(/src/assets/images/bg/countdown-hour.png) left center
		no-repeat;
	background-size: cover;
}
.clockdiv .clockdiv-minute {
	background: url(/src/assets/images/bg/countdown-minute.png) left center
		no-repeat;
	background-size: cover;
}
.card-feature {
	text-align: center;
}
@media (min-width: 992px) {
	.card-feature {
		max-width: 295px;
	}
}
.card-feature .feature-icon {
	width: 99px;
	height: 97px;
	line-height: 97px;
	cursor: pointer;
	margin-right: auto;
	margin-left: auto;
	display: block;
	background: url(./assets/images/theme/feature-icon-bg.png) left center
		no-repeat;
	background-size: cover;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	margin-bottom: 18px;
}
@media (min-width: 991px) {
	.card-feature .feature-icon {
		margin-bottom: 25px;
	}
}
.card-feature .feature-icon i,
.card-feature .feature-icon span {
	font-size: 38px;
	color: #15bfa8;
}
.card-feature .feature-icon [class^='flaticon-']:before,
.card-feature .feature-icon [class*=' flaticon-']:before {
	font-size: 38px;
}
.card-feature h3 {
	font-size: 20px;
	margin-bottom: 10px;
}
@media (min-width: 991px) {
	.card-feature h3 {
		margin-bottom: 20px;
	}
}
.card-feature p {
	font-weight: 300;
}
.card-feature:hover .feature-icon {
	background: url(./assets/images/theme/feature-icon-active-bg.png) left center
		no-repeat;
	background-size: cover;
}
.card-feature:hover .feature-icon i,
.card-feature:hover .feature-icon span {
	color: #fff;
}
.speaker-bg {
	/* background: url(../img/home/speaker-bg.png) left center no-repeat; */
	/* background-size: cover; */
	background-color: #fff;
}
.card-speaker {
	border: 10px solid #fff;
	box-shadow: 0 2px 4px 0 #f7f7f7, 0 4px 16px 0 #f7f7f7;
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;
	border-radius: 18px;
}
.card-speaker .speaker-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;

	background: rgba(13, 17, 48, 0.8);
	/* background-image: linear-gradient(120deg, #1b2364 0%, rgb(54, 168, 113) 100%),
		linear-gradient(120deg, #ee8425 0%, #f9488b 100%); */
	padding: 22px 5px;
	z-index: 2;
	transition: all 0.7s ease;
}
.card-speaker .speaker-footer h4 {
	margin-bottom: 0;
	color: #7affbe;
	font-size: 20px;
	margin-bottom: 3px;
}
.card-speaker:hover .speaker-footer h4 {
	margin-bottom: 0;
	color: #fff;
	font-size: 20px;
	margin-bottom: 3px;
}
.card-speaker .speaker-footer p {
	margin-bottom: 0;
	color: #7affbe;
	font-size: 14px;
}
.card-speaker:hover .speaker-footer p {
	margin-bottom: 0;
	color: #fff;
	font-size: 14px;
}

.card-speaker .speaker-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: rgba(59, 29, 130, 0.8); */
	background: rgba(13, 17, 48, 0.6);
	z-index: 1;
	transform: translateY(-60px);
	opacity: 0;
	z-index: -1;
	transition: all 0.6s ease;
}
.card-speaker:hover .speaker-overlay {
	opacity: 1;
	z-index: 1;
	transform: translateY(0);
}
.card-speaker:hover .speaker-footer {
	background: rgba(54, 168, 113, 0.8);
	color: #0d1130;
}
.speaker-social {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.speaker-social li {
	display: inline-block;
}
.speaker-social li i,
.speaker-social li span {
	font-size: 14px;
	color: #7affbe;
	opacity: 0.7;
}
.speaker-social li::after {
	content: '|';
	color: #0d1130;
	margin-left: 9px;
	margin-right: 7px;
}
.speaker-social li:last-child::after {
	display: none;
}
.speaker-social li:hover i,
.speaker-social li:hover span {
	color: #7affbe;
	opacity: 1;
}
.scheduleTab .nav {
	border-bottom: 0;
	justify-content: center;
	margin-bottom: 40px;
}
@media (min-width: 992px) {
	.scheduleTab .nav {
		margin-bottom: 80px;
	}
}
.scheduleTab .nav-item {
	margin-right: 10px;
}
@media (min-width: 992px) {
	.scheduleTab .nav-item {
		margin-right: 30px;
	}
}
.scheduleTab .nav-item:last-child {
	margin-right: 0;
}
.scheduleTab .nav-item a {
	display: block;
	padding: 8px 16px;
	background: #f7f7f7;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 992px) {
	.scheduleTab .nav-item a {
		padding: 20px 42px;
		margin-bottom: 0;
	}
}
.scheduleTab .nav-item h4 {
	font-size: 20px;
	margin-bottom: 5px;
}
.scheduleTab .nav-item p {
	font-size: 15px;
	color: #797979;
	margin-bottom: 0;
}
.scheduleTab .nav-item a.active {
	/* background: #3b1d82; */
	background-image: linear-gradient(120deg, #233e62 0%, #1b9de3 100%),
		linear-gradient(120deg, #233e62 0%, #1b9de3 100%);
}
.scheduleTab .nav-item a.active > * {
	color: #fff;
}
.card-identity {
	background: #233e62;
	text-align: center;
	padding: 35px 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.card-identity > * {
	color: #7affbe;
}
/* .card-identity:hover > * {
	color: #fff;
}
schedule-card:hover > * {
	color: #fff;
} */
.card-identity img {
	width: 80px;
	height: 80px;
	object-fit: contain;
	display: inline-block;
	border-radius: 40%;
	margin-bottom: 15px;
}
.card-identity h3 {
	margin-bottom: 5px;
}
.schedule-content {
	padding: 10px;
}
@media (min-width: 768px) {
	.schedule-content {
		padding: 5px 40px 5px 40px;
	}
}
.schedule-content .schedule-date {
	font-size: 14px;
	color: #0f867e;
}
.schedule-content .schedule-title {
	display: inline-block;
	margin-bottom: 8px;
}
.schedule-content .schedule-title h3 {
	color: #233e62 !important;
}
.schedule-card {
	background: #f7f7f7;
	margin-bottom: 30px;
}
.schedule-card p {
	margin-bottom: 0;
}
.schedule-card:hover .card-identity {
	background: #04287c;
}
.schedule-card:last-child {
	margin-bottom: 0;
}
.card-blog {
	background: #f7f7f7;
	border-radius: 5px;
	padding: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.card-blog .card-img {
	border-radius: 5px 5px 0 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.card-blog .blog-body {
	padding: 35px 35px 40px 50px;
}
.card-blog .blog-body h3 {
	margin-bottom: 15px;
}
.blog-info li {
	display: inline-block;
	font-size: 14px;
	margin-right: 35px;
}
.blog-info li > * {
	color: #797979;
}
.blog-info li:last-child {
	margin-right: 0;
}
.blog-info li i,
.blog-info li span {
	font-size: 13px;
	padding-right: 5px;
}
.card-blog:hover {
	background: #3b1d82;
}
.card-blog:hover .card-img {
	border-radius: 5px;
}
.card-blog:hover .blog-body h3,
.card-blog:hover a,
.card-blog:hover i {
	color: #fff;
}
.blogCarousel {
	position: relative;
}
.blogCarousel .owl-nav .owl-prev,
.blogCarousel .owl-nav .owl-next {
	width: 45px;
	height: 45px;
	display: block;
	border-radius: 50%;
}
.blogCarousel .owl-nav .owl-prev i,
.blogCarousel .owl-nav .owl-prev span,
.blogCarousel .owl-nav .owl-next i,
.blogCarousel .owl-nav .owl-next span {
	line-height: 45px;
	font-size: 15px;
	color: #3b1d82;
}
.blogCarousel .owl-nav button:not(.disabled) {
	background: #3b1d82;
}
.blogCarousel .owl-nav button:not(.disabled) i,
.blogCarousel .owl-nav button:not(.disabled) span {
	color: #fff;
}
.blogCarousel .owl-nav button.disabled {
	background: #f7f7f7 !important;
	opacity: 1;
	cursor: not-allowed;
}
.blogCarousel .owl-nav button:not(.disabled):hover {
	background: #3b1d82 !important;
}
.blogCarousel .owl-nav button:not(.disabled):hover i,
.blogCarousel .owl-nav button:not(.disabled):hover span {
	color: #fff !important;
}
.blogCarousel .owl-prev {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}
@media (min-width: 1340px) {
	.blogCarousel .owl-prev {
		left: -110px;
	}
}
.blogCarousel .owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}
@media (min-width: 1340px) {
	.blogCarousel .owl-next {
		right: -110px;
	}
}
.card-priceTable {
	background: #fff;
	border-radius: 4px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.priceTable-header {
	padding: 25px 9px;
	border-bottom: 1px solid #ebebeb;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media (min-width: 768px) {
	.priceTable-header {
		padding: 45px 10px 30px 10px;
	}
}
.priceTable-header h3 {
	font-size: 27px;
}
.priceTable-header h1 {
	margin-bottom: 0;
}
.priceTable-price {
	font-size: 22px;
}
@media (min-width: 768px) {
	.priceTable-price {
		font-size: 60px;
	}
}
.priceTable-price span {
	font-size: 22px;
	font-family: 'Roboto', sans-serif;
	display: inline-block;
}
@media (min-width: 768px) {
	.priceTable-price span {
		font-size: 40px;
		position: relative;
		bottom: 7px;
		left: 11px;
	}
}
.priceTable-list {
	padding: 30px 10px;
	border-bottom: 1px solid #ebebeb;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.priceTable-list li {
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.priceTable-list li:last-child {
	margin-bottom: 0;
}
.priceTable-list li i,
.priceTable-list li span {
	font-size: 13px;
	margin-right: 3px;
}
.priceTable-list li i.positive,
.priceTable-list li span.positive {
	color: #797979;
}
.priceTable-list li i.negative,
.priceTable-list li span.negative {
	color: #7affbe;
}
.priceTable-footer {
	padding: 25px 9px;
}
@media (min-width: 768px) {
	.priceTable-footer {
		padding: 45px 0;
	}
}
.priceTable-footer .button {
	border-color: #eeeeee;
	background: #f7f7f7;
}
.card-priceTable:hover {
	background: #3b1d82;
}
.card-priceTable:hover .priceTable-header {
	border-color: rgba(235, 235, 235, 0.2);
}
.card-priceTable:hover .priceTable-header > * {
	color: #fff;
}
.card-priceTable:hover .priceTable-list {
	border-color: rgba(235, 235, 235, 0.2);
}
.card-priceTable:hover .priceTable-list li {
	color: #fff;
}
.card-priceTable:hover .button {
	background: #ea0763;
	border-color: #ea0763;
	color: #fff;
}
.sponsor-bg {
	/* background: url(../img/home/sponsor-bg.png) left center no-repeat; */
	background-size: cover;
}
.sponsor-wrapper {
	text-align: center;
	max-width: 950px;
	margin-right: auto;
	margin-left: auto;
}
.sponsor-wrapper--small {
	max-width: 750px;
}
.sponsor-title {
	text-transform: uppercase;
	font-size: 24px;
}
.sponsor-single {
	border: 5px solid #fff;
	text-align: center;
	padding: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	height: 140px;
	position: relative;
}
.sponsor-single img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.sponsor-single:hover {
	box-shadow: 0px 5px 20px 0px rgba(10, 9, 13, 0.15);
}
.sponsor-wrapper--small .sponsor-single {
	height: 120px;
}
.gallery-bg {
	/* background: url(../img/home/gallery-bg.png) left center no-repeat; */
	background-size: cover;
}
.gallery-area .img-gal {
	display: block;
}
.gallery-area .single-imgs {
	position: relative;
	overflow: hidden;
}
.gallery-area .single-imgs .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(1, 1, 14, 0.4);
	transform: translateY(60px);
	opacity: 0;
	z-index: -1;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.gallery-area .single-imgs .overlay-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.gallery-area .single-imgs .overlay-icon {
	width: 60px;
	height: 60px;
	display: block;
	border-radius: 50%;
	background: rgba(234, 7, 99, 0.8);
	text-align: center;
}
.gallery-area .single-imgs .overlay-icon i,
.gallery-area .single-imgs .overlay-icon span {
	font-size: 20px;
	color: #fff;
	line-height: 60px;
}
.gallery-area .single-imgs:hover .overlay {
	transform: translateY(0);
	opacity: 1;
	z-index: 1;
}
.contact-info {
	margin-bottom: 12px;
}
.contact-info__icon {
	margin-right: 20px;
}
.contact-info__icon i,
.contact-info__icon span {
	color: #8f9195;
	font-size: 27px;
}
.contact-info .media-body h3 {
	font-size: 16px;
	margin-bottom: 0;
}
.contact-info .media-body h3 a {
	color: #242424;
}
.contact-info .media-body p {
	color: #999999;
}
.contact-title {
	font-size: 27px;
	font-weight: 600;
	margin-bottom: 20px;
}
.form-contact label {
	color: #3b1d82;
	font-size: 14px;
}
.form-contact .form-group {
	margin-bottom: 30px;
}
.form-contact .form-control {
	border: 1px solid #f0e9ff;
	border-radius: 5px;
	height: 48px;
	padding-left: 18px;
	font-size: 13px;
	background: transparent;
}
.form-contact .form-control:focus {
	outline: 0;
	box-shadow: none;
}
.form-contact .form-control::placeholder {
	font-weight: 300;
	color: #999999;
}
.form-contact textarea {
	border-radius: 12px;
	height: 100% !important;
}
.l_blog_item .l_blog_text .date {
	margin-top: 24px;
	margin-bottom: 15px;
}
.l_blog_item .l_blog_text .date a {
	font-size: 12px;
}
.l_blog_item .l_blog_text h4 {
	font-size: 18px;
	color: #242424;
	border-bottom: 1px solid #eeeeee;
	margin-bottom: 0px;
	padding-bottom: 20px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.l_blog_item .l_blog_text h4:hover {
	color: #3b1d82;
}
.l_blog_item .l_blog_text p {
	margin-bottom: 0px;
	padding-top: 20px;
}
.causes_slider .owl-dots {
	text-align: center;
	margin-top: 80px;
}
.causes_slider .owl-dots .owl-dot {
	height: 14px;
	width: 14px;
	background: #eeeeee;
	display: inline-block;
	margin-right: 7px;
}
.causes_slider .owl-dots .owl-dot:last-child {
	margin-right: 0px;
}
.causes_slider .owl-dots .owl-dot.active {
	background: #3b1d82;
}
.causes_item {
	background: #fff;
}
.causes_item .causes_img {
	position: relative;
}
.causes_item .causes_img .c_parcent {
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
	height: 3px;
	background: rgba(255, 255, 255, 0.5);
}
.causes_item .causes_img .c_parcent span {
	width: 70%;
	height: 3px;
	background: #3b1d82;
	position: absolute;
	left: 0px;
	bottom: 0px;
}
.causes_item .causes_img .c_parcent span:before {
	content: '75%';
	position: absolute;
	right: -10px;
	bottom: 0px;
	background: #3b1d82;
	color: #fff;
	padding: 0px 5px;
}
.causes_item .causes_text {
	padding: 30px 35px 40px 30px;
}
.causes_item .causes_text h4 {
	color: #242424;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
	cursor: pointer;
}
.causes_item .causes_text h4:hover {
	color: #3b1d82;
}
.causes_item .causes_text p {
	font-size: 14px;
	line-height: 24px;
	font-weight: 300;
	margin-bottom: 0px;
}
.causes_item .causes_bottom a {
	width: 50%;
	border: 1px solid #3b1d82;
	text-align: center;
	float: left;
	line-height: 50px;
	background: #3b1d82;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}
.causes_item .causes_bottom a + a {
	border-color: #eeeeee;
	background: #fff;
	font-size: 14px;
	color: #242424;
}
.latest_blog_area {
	background: #f9f9ff;
}
.single-recent-blog-post {
	margin-bottom: 30px;
}
.single-recent-blog-post .thumb {
	overflow: hidden;
}
.single-recent-blog-post .thumb img {
	transition: all 0.7s linear;
}
.single-recent-blog-post .details {
	padding-top: 30px;
}
.single-recent-blog-post .details .sec_h4 {
	line-height: 24px;
	padding: 10px 0px 13px;
	transition: all 0.3s linear;
}
.single-recent-blog-post .date {
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
}
.single-recent-blog-post:hover img {
	transform: scale(1.23) rotate(10deg);
}
.tags .tag_btn {
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	border: 1px solid #eeeeee;
	display: inline-block;
	padding: 1px 18px;
	text-align: center;
	color: #242424;
}
.tags .tag_btn:before {
	background: #3b1d82;
}
.tags .tag_btn + .tag_btn {
	margin-left: 2px;
}
.blog_categorie_area {
	padding-top: 30px;
	padding-bottom: 30px;
}
@media (min-width: 900px) {
	.blog_categorie_area {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}
@media (min-width: 1100px) {
	.blog_categorie_area {
		padding-top: 120px;
		padding-bottom: 120px;
	}
}
.categories_post {
	position: relative;
	text-align: center;
	cursor: pointer;
}
.categories_post img {
	max-width: 100%;
}
.categories_post .categories_details {
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	background: rgba(34, 34, 34, 0.75);
	color: #fff;
	transition: all 0.3s linear;
	display: flex;
	align-items: center;
	justify-content: center;
}
.categories_post .categories_details h5 {
	margin-bottom: 0px;
	font-size: 18px;
	line-height: 26px;
	text-transform: uppercase;
	color: #fff;
	position: relative;
}
.categories_post .categories_details p {
	font-weight: 300;
	font-size: 14px;
	line-height: 26px;
	margin-bottom: 0px;
}
.categories_post .categories_details .border_line {
	margin: 10px 0px;
	background: #fff;
	width: 100%;
	height: 1px;
}
.categories_post:hover .categories_details {
	background: rgba(222, 99, 32, 0.85);
}
.blog_item {
	margin-bottom: 50px;
}
.blog_details {
	padding: 30px 0 20px 10px;
	box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}
@media (min-width: 768px) {
	.blog_details {
		padding: 60px 30px 35px 35px;
	}
}
.blog_details p {
	margin-bottom: 30px;
}
.blog_details h2 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 8px;
}
@media (min-width: 768px) {
	.blog_details h2 {
		font-size: 24px;
		margin-bottom: 15px;
	}
}
.blog-info-link li {
	float: left;
	font-size: 14px;
}
.blog-info-link li a {
	color: #999999;
}
.blog-info-link li i,
.blog-info-link li span {
	font-size: 13px;
	margin-right: 5px;
}
.blog-info-link li::after {
	content: '|';
	padding-left: 10px;
	padding-right: 10px;
}
.blog-info-link li:last-child::after {
	display: none;
}
.blog-info-link::after {
	content: '';
	display: block;
	clear: both;
	display: table;
}
.blog_item_img {
	position: relative;
}
.blog_item_img .blog_item_date {
	position: absolute;
	bottom: -10px;
	left: 10px;
	display: block;
	color: #fff;
	background: #ea0763;
	padding: 8px 15px;
	border-radius: 5px;
}
@media (min-width: 768px) {
	.blog_item_img .blog_item_date {
		bottom: -20px;
		left: 40px;
		padding: 13px 30px;
	}
}
.blog_item_img .blog_item_date h3 {
	font-size: 22px;
	font-weight: 600;
	color: #fff;
	margin-bottom: 0;
	line-height: 1.2;
}
@media (min-width: 768px) {
	.blog_item_img .blog_item_date h3 {
		font-size: 30px;
	}
}
.blog_item_img .blog_item_date p {
	margin-bottom: 0;
}
@media (min-width: 768px) {
	.blog_item_img .blog_item_date p {
		font-size: 18px;
	}
}
.blog_right_sidebar .widget_title {
	font-size: 20px;
	margin-bottom: 40px;
}
.blog_right_sidebar .widget_title::after {
	content: '';
	display: block;
	padding-top: 15px;
	border-bottom: 1px solid #f0e9ff;
}
.blog_right_sidebar .single_sidebar_widget {
	background: #fbf9ff;
	padding: 30px;
	margin-bottom: 30px;
}
.blog_right_sidebar .search_widget .form-control {
	height: 50px;
	border-color: #f0e9ff;
	font-size: 13px;
	color: #999999;
	padding-left: 20px;
	border-radius: 0;
	border-right: 0;
}
.blog_right_sidebar .search_widget .form-control::placeholder {
	color: #999999;
}
.blog_right_sidebar .search_widget .form-control:focus {
	border-color: #f0e9ff;
	outline: 0;
	box-shadow: none;
}
.blog_right_sidebar .search_widget .input-group button {
	background: #fff;
	border-left: 0;
	border: 1px solid #f0e9ff;
	padding: 4px 15px;
	border-left: 0;
}
.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
	font-size: 14px;
	color: #999999;
}
.blog_right_sidebar .newsletter_widget .form-control {
	height: 50px;
	border-color: #f0e9ff;
	font-size: 13px;
	color: #999999;
	padding-left: 20px;
	border-radius: 0;
}
.blog_right_sidebar .newsletter_widget .form-control::placeholder {
	color: #999999;
}
.blog_right_sidebar .newsletter_widget .form-control:focus {
	border-color: #f0e9ff;
	outline: 0;
	box-shadow: none;
}
.blog_right_sidebar .newsletter_widget .input-group button {
	background: #fff;
	border-left: 0;
	border: 1px solid #f0e9ff;
	padding: 4px 15px;
	border-left: 0;
}
.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
	font-size: 14px;
	color: #999999;
}
.blog_right_sidebar .post_category_widget .cat-list li {
	border-bottom: 1px solid #f0e9ff;
	transition: all 0.3s ease 0s;
	padding-bottom: 12px;
}
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
	border-bottom: 0;
}
.blog_right_sidebar .post_category_widget .cat-list li a {
	font-size: 14px;
	line-height: 20px;
	color: #888888;
}
.blog_right_sidebar .post_category_widget .cat-list li a p {
	margin-bottom: 0px;
}
.blog_right_sidebar .post_category_widget .cat-list li + li {
	padding-top: 15px;
}
.blog_right_sidebar .post_category_widget .cat-list li:hover a {
	color: #ea0763;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body {
	justify-content: center;
	align-self: center;
	padding-left: 20px;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 6px;
	transition: all 0.3s linear;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
	color: #3b1d82;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 0px;
}
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
	margin-top: 20px;
}
.blog_right_sidebar .tag_cloud_widget ul li {
	display: inline-block;
}
.blog_right_sidebar .tag_cloud_widget ul li a {
	display: inline-block;
	border: 1px solid #eeeeee;
	background: #fff;
	padding: 4px 20px;
	margin-bottom: 8px;
	margin-right: 5px;
	transition: all 0.3s ease 0s;
	color: #888888;
	font-size: 13px;
}
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
	background: #3b1d82;
	color: #fff;
}
.blog_right_sidebar .instagram_feeds .instagram_row {
	display: flex;
	margin-right: -6px;
	margin-left: -6px;
}
.blog_right_sidebar .instagram_feeds .instagram_row li {
	width: 33.33%;
	float: left;
	padding-right: 6px;
	padding-left: 6px;
	margin-bottom: 15px;
}
.blog_right_sidebar .br {
	width: 100%;
	height: 1px;
	background: #eee;
	margin: 30px 0px;
}
.blog-pagination {
	margin-top: 80px;
}
.blog-pagination .page-link {
	font-size: 14px;
	position: relative;
	display: block;
	padding: 0;
	text-align: center;
	margin-left: -1px;
	line-height: 45px;
	width: 45px;
	height: 45px;
	border-radius: 0 !important;
	color: #8a8a8a;
	border: 1px solid #f0e9ff;
	margin-right: 10px;
}
.blog-pagination .page-link i,
.blog-pagination .page-link span {
	font-size: 13px;
}
.blog-pagination .page-link:hover {
	background-color: #fbf9ff;
}
.blog-pagination .page-item.active .page-link {
	background-color: #fbf9ff;
	border-color: #f0e9ff;
	color: #888888;
}
.blog-pagination .page-item:last-child .page-link {
	margin-right: 0;
}
.single-post-area .blog_details {
	box-shadow: none;
	padding: 0;
}
.single-post-area .social-links {
	padding-top: 10px;
}
.single-post-area .social-links li {
	display: inline-block;
	margin-bottom: 10px;
}
.single-post-area .social-links li a {
	color: #cccccc;
	padding: 7px;
	font-size: 14px;
	transition: all 0.2s linear;
}
.single-post-area .social-links li a:hover {
	color: #242424;
}
.single-post-area .blog_details {
	padding-top: 26px;
}
.single-post-area .blog_details p {
	margin-bottom: 20px;
}
.single-post-area .quote-wrapper {
	background: rgba(130, 139, 178, 0.1);
	padding: 15px;
	line-height: 1.733;
	color: #888888;
	font-style: italic;
	margin-top: 25px;
	margin-bottom: 25px;
}
@media (min-width: 768px) {
	.single-post-area .quote-wrapper {
		padding: 30px;
	}
}
.single-post-area .quotes {
	background: #fff;
	padding: 15px 15px 15px 20px;
	border-left: 2px solid #3b1d82;
}
@media (min-width: 768px) {
	.single-post-area .quotes {
		padding: 25px 25px 25px 30px;
	}
}
.single-post-area .arrow {
	position: absolute;
}
.single-post-area .arrow .lnr {
	font-size: 20px;
	font-weight: 600;
}
.single-post-area .thumb .overlay-bg {
	background: rgba(0, 0, 0, 0.8);
}
.single-post-area .navigation-top {
	padding-top: 15px;
	border-top: 1px solid #f0e9ff;
}
.single-post-area .navigation-top p {
	margin-bottom: 0;
}
.single-post-area .navigation-top .like-info {
	font-size: 14px;
}
.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
	font-size: 16px;
	margin-right: 5px;
}
.single-post-area .navigation-top .comment-count {
	font-size: 14px;
}
.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
	font-size: 16px;
	margin-right: 5px;
}
.single-post-area .navigation-top .social-icons li {
	display: inline-block;
	margin-right: 15px;
}
.single-post-area .navigation-top .social-icons li:last-child {
	margin: 0;
}
.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
	font-size: 14px;
	color: #999999;
}
.single-post-area .navigation-top .social-icons li:hover i,
.single-post-area .navigation-top .social-icons li:hover span {
	color: #ea0763;
}
.single-post-area .blog-author {
	padding: 40px 30px;
	background: #fbf9ff;
	margin-top: 50px;
}
@media (max-width: 600px) {
	.single-post-area .blog-author {
		padding: 20px 8px;
	}
}
.single-post-area .blog-author img {
	width: 90px;
	height: 90px;
	border-radius: 50%;
	margin-right: 30px;
}
@media (max-width: 600px) {
	.single-post-area .blog-author img {
		margin-right: 15px;
		width: 45px;
		height: 45px;
	}
}
.single-post-area .blog-author a {
	display: inline-block;
}
.single-post-area .blog-author p {
	margin-bottom: 0;
}
.single-post-area .blog-author h4 {
	font-size: 16px;
}
.single-post-area .navigation-area {
	border-bottom: 1px solid #eee;
	padding-bottom: 30px;
	margin-top: 55px;
}
.single-post-area .navigation-area p {
	margin-bottom: 0px;
}
.single-post-area .navigation-area h4 {
	font-size: 18px;
	line-height: 25px;
	color: #242424;
}
.single-post-area .navigation-area .nav-left {
	text-align: left;
}
.single-post-area .navigation-area .nav-left .thumb {
	margin-right: 20px;
	background: #000;
}
.single-post-area .navigation-area .nav-left .thumb img {
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left .lnr {
	margin-left: 20px;
	opacity: 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left:hover .lnr {
	opacity: 1;
}
.single-post-area .navigation-area .nav-left:hover .thumb img {
	opacity: 0.5;
}
@media (max-width: 767px) {
	.single-post-area .navigation-area .nav-left {
		margin-bottom: 30px;
	}
}
.single-post-area .navigation-area .nav-right {
	text-align: right;
}
.single-post-area .navigation-area .nav-right .thumb {
	margin-left: 20px;
	background: #000;
}
.single-post-area .navigation-area .nav-right .thumb img {
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right .lnr {
	margin-right: 20px;
	opacity: 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right:hover .lnr {
	opacity: 1;
}
.single-post-area .navigation-area .nav-right:hover .thumb img {
	opacity: 0.5;
}
@media (max-width: 991px) {
	.single-post-area .sidebar-widgets {
		padding-bottom: 0px;
	}
}
.comments-area {
	background: transparent;
	border-top: 1px solid #eee;
	padding: 45px 0;
	margin-top: 50px;
}
@media (max-width: 414px) {
	.comments-area {
		padding: 50px 8px;
	}
}
.comments-area h4 {
	margin-bottom: 35px;
	color: #242424;
	font-size: 18px;
}
.comments-area h5 {
	font-size: 16px;
	margin-bottom: 0px;
}
.comments-area a {
	color: #242424;
}
.comments-area .comment-list {
	padding-bottom: 48px;
}
.comments-area .comment-list:last-child {
	padding-bottom: 0px;
}
.comments-area .comment-list.left-padding {
	padding-left: 25px;
}
@media (max-width: 413px) {
	.comments-area .comment-list .single-comment h5 {
		font-size: 12px;
	}
	.comments-area .comment-list .single-comment .date {
		font-size: 11px;
	}
	.comments-area .comment-list .single-comment .comment {
		font-size: 10px;
	}
}
.comments-area .thumb {
	margin-right: 20px;
}
.comments-area .thumb img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
.comments-area .date {
	font-size: 14px;
	color: #cccccc;
	margin-bottom: 0;
	margin-left: 20px;
}
.comments-area .comment {
	margin-bottom: 10px;
	color: #777777;
}
.comments-area .btn-reply {
	background-color: transparent;
	color: #888888;
	padding: 5px 18px;
	font-size: 14px;
	display: block;
	font-weight: 400;
}
.comment-form {
	border-top: 1px solid #eee;
	padding-top: 45px;
	margin-top: 50px;
	margin-bottom: 20px;
}
.comment-form .form-group {
	margin-bottom: 30px;
}
.comment-form h4 {
	margin-bottom: 40px;
	font-size: 18px;
	line-height: 22px;
	color: #242424;
}
.comment-form .name {
	padding-left: 0px;
}
@media (max-width: 767px) {
	.comment-form .name {
		padding-right: 0px;
		margin-bottom: 1rem;
	}
}
.comment-form .email {
	padding-right: 0px;
}
@media (max-width: 991px) {
	.comment-form .email {
		padding-left: 0px;
	}
}
.comment-form .form-control {
	border: 1px solid #f0e9ff;
	border-radius: 5px;
	height: 48px;
	padding-left: 18px;
	font-size: 13px;
	background: transparent;
}
.comment-form .form-control:focus {
	outline: 0;
	box-shadow: none;
}
.comment-form .form-control::placeholder {
	font-weight: 300;
	color: #999999;
}
.comment-form .form-control::placeholder {
	color: #777777;
}
.comment-form textarea {
	padding-top: 18px;
	border-radius: 12px;
	height: 100% !important;
}
.comment-form ::-webkit-input-placeholder {
	font-size: 13px;
	color: #777;
}
.comment-form ::-moz-placeholder {
	font-size: 13px;
	color: #777;
}
.comment-form :-ms-input-placeholder {
	font-size: 13px;
	color: #777;
}
.comment-form :-moz-placeholder {
	font-size: 13px;
	color: #777;
}
.button {
	display: inline-block;
	border: 1px solid transparent;
	font-size: 15px;
	font-weight: 500;
	padding: 12px 54px;
	border-radius: 4px;
	color: #242424;
	border: 1px solid #233e62;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media (max-width: 767px) {
	.button {
		font-size: 13px;
		padding: 9px 24px;
	}
}
.button:hover {
	background: #0a44c9;
	color: #fff;
}
.button-link {
	letter-spacing: 0;
	color: #0a44c9;
	border: 0;
	padding: 0;
}
.button-link:hover {
	background: transparent;
	color: #0a44c9;
}
.button-header {
	background: #233e62;
	color: #fff;
	border-color: #233e62;
}
.button-header:hover {
	background: #04287c;
	color: #fff;
	border-color: #04287c;
}
.button-contactForm {
	background: #3b1d82;
	color: #fff;
	border-color: #3b1d82;
}
.button-contactForm:hover {
	border-color: #ea0763;
	background: #b8024c;
	color: #fff;
}
.footer-area {
	background: #111429;
	padding-top: 60px;
}
@media (min-width: 1000px) {
	.footer-area {
		padding-top: 110px;
	}
}
.footer-area .footer-nav li {
	margin-top: 18px;
}
.footer-area .footer-nav li a {
	color: #fff;
}
.footer-area .footer-nav li a:hover {
	color: #3b1d82;
}
.footer-area .primary-btn {
	background-color: #3b1d82;
	line-height: 42px;
	padding-left: 30px;
	padding-right: 60px;
	border-radius: 25px;
	border: none;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	position: relative;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	text-transform: uppercase;
	position: relative;
}
.footer-area .primary-btn:focus {
	outline: none;
}
.footer-area .primary-btn span {
	color: #fff;
	position: absolute;
	top: 50%;
	transform: translateY(-60%);
	right: 30px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.footer-area .primary-btn:hover {
	background-color: #3b1d82;
	box-shadow: none;
	color: #fff;
}
.footer-area .primary-btn:hover span {
	color: #fff;
	right: 20px;
}
.footer-area .primary-btn.white {
	border: 1px solid #fff;
	color: #fff;
}
.footer-area .primary-btn.white span {
	color: #fff;
}
.footer-area .primary-btn.white:hover {
	background: #fff;
	color: #3b1d82;
}
.footer-area .primary-btn.white:hover span {
	color: #3b1d82;
}
.footer-area h6 {
	text-transform: uppercase;
	color: #fff;
	margin-bottom: 25px;
	font-size: 20px;
	font-weight: 700;
}
.copy-right-text i,
.copy-right-text a {
	color: #3b1d82;
}
.instafeed {
	margin: -5px;
}
.instafeed li {
	overflow: hidden;
	width: 25%;
	margin-bottom: 0 !important;
}
.instafeed li img {
	margin: 5px;
}
.footer-social {
	text-align: right;
}
.footer-social a {
	width: 20px;
	width: 40px;
	display: inline-table;
	height: 40px;
	text-align: center;
	padding-top: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.footer-social a:hover i {
	color: #7affbe;
}
.footer-social i,
.footer-social span {
	font-size: 18px;
	color: rgba(221, 221, 221, 0.5);
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
	.footer-social {
		text-align: left;
		margin-top: 20px;
	}
}
.single-footer-widget {
	color: #fff;
}
.single-footer-widget input {
	line-height: 38px;
	border: none;
	background: #fff;
	border: 1px solid #fff;
	font-weight: 400;
	color: #999999;
	padding-left: 20px;
	width: 80%;
	font-size: 14px;
}
.single-footer-widget input::placeholder {
	font-weight: 400;
}
.single-footer-widget .bb-btn {
	background-color: #ea0763;
	color: #fff;
	font-size: 13px;
	font-weight: 300;
	border-radius: 0;
	cursor: pointer;
}
@media (max-width: 960px) {
	.single-footer-widget .nw-btn {
		margin-top: 20px;
	}
	.single-footer-widget .newsletter {
		padding-bottom: 40px;
	}
}
.single-footer-widget .info {
	position: absolute;
	margin-top: 3px;
	color: #fff;
	font-size: 14px;
}
.single-footer-widget .info.valid {
	color: green;
}
.single-footer-widget .info.error {
	color: #ea0763;
}
.single-footer-widget ::-moz-selection {
	background-color: #ea0763;
	color: #797979;
}
.single-footer-widget ::selection {
	background-color: #ea0763;
	color: #797979;
}
.single-footer-widget ::-webkit-input-placeholder {
	color: #797979;
	font-weight: 300;
}
.single-footer-widget :-moz-placeholder {
	color: #797979;
	opacity: 1;
	font-weight: 300;
}
.single-footer-widget ::-moz-placeholder {
	color: #797979;
	opacity: 1;
	font-weight: 300;
}
.single-footer-widget :-ms-input-placeholder {
	color: #797979;
	font-weight: 300;
}
.single-footer-widget ::-ms-input-placeholder {
	color: #797979;
	font-weight: 300;
}
@media (max-width: 991.98px) {
	.single-footer-widget {
		margin-bottom: 45px;
	}
}
.single-footer-widget p {
	color: #666;
}
.single-footer-widget ul li {
	margin-bottom: 10px;
}
.single-footer-widget ul li a {
	color: #666;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-footer-widget ul li a:hover {
	color: #7affbe;
}
.footer-bottom {
	padding: 20px 0;
	margin-top: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 1000px) {
	.footer-bottom {
		margin-top: 70px;
	}
}
.footer-text {
	color: rgb(193, 193, 193);
}
.footer-text a,
.footer-text i {
	color: #7affbe;
}
