@import url("https://fonts.googleapis.com/css?family=Oswald:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

/*---------------------------------------------*/

/* p {
	font-family: "Oswald", sans-serif;
	font-size: 14px;
	line-height: 1.7;
	color: #312efc;
	margin: 0px;
} */
.color-white {
	color: #fff !important ;
	font-weight: 600 !important;
	padding: 0 !important;
}

.color-title {
	color: #fff !important;
}
.padding-title100 {
	padding-top: 0% !important;
	padding-bottom: 0% !important;
}
.container-contact100 {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(120deg, #233e62 0%, #1b9de3 100%),
		linear-gradient(120deg, #233e62 0%, #1b9de3 100%);
}

.wrap-contact100 {
	width: 1163px;
	background: rgb(255, 255, 255);
	border-radius: 10px;
	overflow: hidden;

	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 30px 130px 15px 148px;
}

/*------------------------------------------------------------------
[  ]*/
.contact100-pic {
	position: relative;
	width: 310px;
	padding-top: 55px;
}

.contact100-pic img {
	max-width: 100%;
}

/*------------------------------------------------------------------
[  ]*/
.contact100-form {
	width: 390px;
}

.contact100-form-title {
	display: block;
	font-family: "Oswald", sans-serif;
	font-size: 24px;
	color: #233e62 !important ;
	line-height: 1.2;
	text-align: left;
	padding-bottom: 36px;
}

input.input100 {
	height: 50px;

	padding: 0 30px 0 50px;
}

input.input100[name="email"] {
	padding: 14px 30px;
}

textarea.input100 {
	min-height: 150px;

	padding: 14px 30px;
}

/*---------------------------------------------*/
.wrap-input100 {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: 10px;
}
.duo-bar {
	display: inline-block !important;
}
.input100 {
	display: block;
	width: 100%;
	background: #e6e6e6;
	font-family: "Oswald", sans-serif;
	font-size: 15px;
	line-height: 1.5;
	color: #233e62;
	border: solid;
	border-radius: 1px;
	border-color: #233e62;
}
.input100:focus {
	display: block !important;
	width: 100% !important;
	background: #e6e6e6 !important;
	font-family: "Oswald", sans-serif !important;
	font-size: 15px !important;
	line-height: 1.5 !important;
	color: #233e62 !important;
	border: solid !important;
	border-color: #233e62 !important;
	border-radius: 25px !important ;
}
.name100 {
	padding-left: 15% !important;
}
.object100 {
	padding-left: 15% !important;
}
/*------------------------------------------------------------------
[ Focus ]*/

@-webkit-keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 60px 25px;
		opacity: 0;
	}
}

@keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 60px 25px;
		opacity: 0;
	}
}

.symbol-input100 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: absolute;
	border-radius: 25px;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 30px;
	pointer-events: none;
	color: #aaaaaa;
	font-size: 15px;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 120px;
}

.contact100-form-btn {
	width: 100%;
	height: 50px;
	border: solid;
	border-radius: 25px;
	border-color: #233e62;
	background: #fff;
	font-family: "Oswald", sans-serif;
	font-size: 15px;
	line-height: 1.5;
	color: #233e62;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.contact100-form-btn:hover {
	background: #233e62;
	color: rgb(255, 255, 255);
}
.contact100-pic {
	padding-top: 120px;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1200px) {
	.contact100-pic {
		width: 33.5%;
	}

	.contact100-form {
		width: 44%;
	}
}

@media (max-width: 992px) {
	.wrap-contact100 {
		padding: 110px 80px 157px 90px;
	}

	.contact100-pic {
		width: 35%;
	}

	.contact100-form {
		width: 55%;
	}
}

@media (max-width: 768px) {
	.wrap-contact100 {
		padding: 110px 80px 157px 80px;
	}

	.contact100-pic {
		display: none;
	}

	.contact100-form {
		width: 100%;
	}
}

@media (max-width: 576px) {
	.wrap-contact100 {
		padding: 110px 15px 157px 15px;
	}
}
