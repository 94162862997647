.pt100 {
	padding-top: 100px !important;
}
.pb {
	padding-bottom: 100px !important;
}
.section_title {
	position: relative;
	margin-bottom: 25px;
}
.section_title .title {
	text-transform: uppercase;
	padding: 10px 0;
	font-size: 36px;
}

h3,
.h3 {
	font-size: 36px;
	margin-bottom: 25px;
}
.aboutUsP {
	font-size: 12px;
	margin: 0 0 10px;
	line-height: 20px !important;
	font-weight: 300 !important;
	margin-bottom: 10%;
	color: #838383;
	font-weight: 300;
}
@media (max-width: 500px) {
	.aboutUsP {
		text-align: center;
	}
}
.icon_box_one {
	background-color: #f0f2f6;
	padding: 0;
	margin: 25px 0;
	height: 90%;

	position: relative;
	overflow: hidden;
	-webkit-box-shadow: 0 0 0 rgb(0 0 0 / 8%);
	box-shadow: 0 0 0 rgb(0 0 0 / 8%);
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one .content {
	margin-top: 0;
	padding: 10px 25px 25px;
}
.icon_box_one:hover .content p {
	color: #fff;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one .content p {
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one:hover i {
	color: #fff;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one:hover .content h4 {
	color: #fff;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one:hover .content a {
	color: #fff;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.icon_box_one:hover {
	background-image: linear-gradient(120deg, #1b9de3 0%, #0a44c9 100%),
		linear-gradient(120deg, #1b9de3 0%, #0a44c9 100%);
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.lnr-mic:before {
	content: 'fas fa-microphone';
}
.icon_box_one i {
	margin-top: -10px;
	padding: 0 15px;
	font-size: 72px;
	color: #f50136;
	vertical-align: middle;
	border-radius: 4px;
	display: block;
	margin-bottom: 15px;
	line-height: 1;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.icon_box_one .content h4 {
	color: #233e62;
	margin-bottom: 12%;

	margin-top: 10%;
	text-transform: capitalize;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
	.icon_box_one .content h4 {
		text-align: justify !important;
	}
}
.icon_box_one:hover i {
	color: #fff;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.text-part {
	font-size: 16px;
	line-height: 1.5;
	margin-top: 0;
	margin-bottom: 1rem;
}
@media (max-width: 500px) {
	.pad_icon {
		margin-left: 35%;
	}
}
