.section_profile {
	background-color: -webkit-linear-gradient(left, #3931af, #00c6ff) !important;
	background: #f7f7f7;
}
.emp-profile {
	padding: 10%;
	margin-bottom: 3%;
	border-radius: 0.5rem;
	background: #fff;
}
.profile-img {
	text-align: center;
}
.profile-img img {
	width: 70%;
	height: 100%;
}
.profile-img .file {
	position: relative;
	overflow: hidden;
	margin-top: -20%;
	width: 70%;
	border: none;
	border-radius: 0;
	font-size: 15px;
	background: #212529b8;
}
.profile-img .file input {
	position: absolute;
	opacity: 0;
	right: 0;
	top: 0;
}
.profile-head h5 {
	color: #333;
}
.profile-head h6 {
	color: #6c757d;
}
.profile-edit-btn {
	border: none;
	border-radius: 1.5rem;
	width: 70%;
	padding: 2%;
	font-weight: 600;
	color: #6c757d;
	cursor: pointer;
}
.proile-rating {
	font-size: 12px;
	color: #818182;
	margin-top: 5%;
}
.proile-rating span {
	color: #495057;
	font-size: 15px;
	font-weight: 600;
}
.profile-head .nav-tabs {
	margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
	font-weight: 600;
	border: none;
}
.profile-head .nav-tabs .nav-link.active {
	border: none;
	border-bottom: 2px solid #36e7f1;
}
.profile-work {
	padding: 14%;
	margin-top: -15%;
}
.profile-work p {
	font-size: 12px;
	color: #818182;
	font-weight: 600;
	margin-top: 10%;
}
.profile-work div a {
	text-decoration: none;
	color: #495057;
	font-weight: 600;
	font-size: 14px;
}
.profile-work ul {
	list-style: none;
}
.profile-tab label {
	font-weight: 600;
}
.profile-tab p {
	font-weight: 600;
	color: #0d1130;
}
.about-me {
	color: #0f867e !important;
	margin-bottom: 4px;
	padding-bottom: 2%;
}

.colorSpeakerAbout {
	color: #0f867e;
}
