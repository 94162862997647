.team {
	text-align: center;
	color: #313131;
	text-transform: uppercase;
	margin: 25px 0;
}
.team h4 {
	margin-top: 15px;
	margin-bottom: 5px;
}
.team p {
	margin-bottom: 5px;
}
.team img:hover {
	opacity: 0.7;
}
.team img {
	width: 250px;
}
.social-block {
	list-style-type: none;
	padding: 0;
}
.social-block li {
	display: inline-block;
}
.social-block li a {
	display: inline-block;
	height: 32px;
	width: 32px;
	color: #4e4e4e;
}
.social-block li a i {
	font-size: 1.5em;
}
.social-block li a:hover,
.social-block li a:focus {
	color: #000;
}
